/*------------------------------------------
 DOM and basic helpers
 ------------------------------------------*/
String.prototype.trunc = String.prototype.trunc || function(n) {
    return this.length > n ? this.substr(0, n - 1) + '...' : this;
};

function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function getDataset(el) {
    var dataset = Array.prototype.filter.call(el.attributes, function(at) {
        return /^data-/.test(at.name);
    });
    return dataset;
}

function hasClass(el, className) {
    if (el.classList)
        return el.classList.contains(className);
    else
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
}

function addClass(el, className) {
    if (el.classList)
        el.classList.add(className);
    else
        el.className += ' ' + className;
}

function removeClass(el, className) {
    if (el.classList)
        el.classList.remove(className);
    else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

function removeClasses(el, className) {
    if (el.length) {
        while (el.length > 0) {
            el[0].classList.remove(className);
        }
    }
}

function toggleClass(el, className) {
    if (el.classList) {
        el.classList.toggle(className);
    } else {
        var classes = el.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        el.className = classes.join(' ');
    }
}

/*------------------------------------------
  AJAX requests helper
------------------------------------------*/
var ajax = {

    newRequest: function(method, url, params, callback, context) {
        this.context = context;
        this.req = new XMLHttpRequest();
        this.req.open(method, url, true);

        if (params) {
            this.params = this.encodeParams(params);
        } else {
            this.params = '';
        }

        if (method === 'POST') {
            this.req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        }

        var _this = this;

        this.req.onload = function() {
            if (this.status >= 200 && this.status < 400) {
                // AJAX succeed
                var data;
                if (this.responseText === 0) {
                    data = {
                        error: 'Invalid action.'
                    };
                } else {
                    data = JSON.parse(this.responseText);
                }
                callback.call(_this.context, data);
            } else {
                // Server error
                callback.call(_this.context, {
                    error: 'Server error.'
                });
            }
        };

        this.req.onerror = function() {
            // Connection error
            callback.call(_this.context, {
                error: 'Connection error.'
            });
        };

        this.sendRequest();
    },

    encodeParams: function(params) {
        // Encode params object to URL params
        var encodedParams = Object.keys(params).map(function(k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
        }).join('&');

        return encodedParams;
    },

    sendRequest: function() {
        // Send the request
        this.req.send(this.params);
    }

};

/*------------------------------------------
  Timer
------------------------------------------*/
var timer = function() {

    // Elements to animate
    var elems = [
        document.querySelector('.t-days'),
        document.querySelector('.t-hours'),
        document.querySelector('.t-minutes')
    ];

    var objs = [];
    setTimeout(function() {
        // Initialize and run animation for each element
        for (var i = 0, l = elems.length; i < l; i++) {
            objs.push(
                new VIO_INC({
                    elem: elems[i],
                    speed: 30
                })
            );
        }
    }, 500);
};

/*------------------------------------------
  Sign-up
------------------------------------------*/
var signUp = function() {

    var proFields = document.querySelector('.pro-fields');
    // Hide pro fields on load
    addClass(proFields, 'hidden');
    // Display pro fields on load if form was posted checked
    if (document.querySelector('#user_ispro').checked)
        removeClass(proFields, 'hidden');

    // Show/hide extra pro fields when checkbox is checked
    document.querySelector('#user_ispro').addEventListener('change', function() {
        if (this.checked) {
            Velocity(proFields, 'transition.slideDownBigIn', {
                duration: 500
            });
            Velocity(proFields, 'scroll', {
                duration: 1000
            });
        } else {
            Velocity(proFields, 'reverse', {
                duration: 1000
            });
            Velocity(proFields, {
                height: 0,
                opacity: 0,
                queue: false,
                duration: 500
            }, {
                complete: function(elements) {
                    elements[0].style.display = 'none';
                    elements[0].style.height = 'auto';
                }
            });
        }
    });

};

/*------------------------------------------
  Log-in
------------------------------------------*/
var logIn = function() {

    var form = document.getElementById('login_top');

    var button = document.querySelector('a[href="#login_top"]');
    button.addEventListener('click', function(e) {
        e.preventDefault();
        // Check if form is visible and then anime in or out
        if (form.offsetHeight === 0)
            Velocity(form, 'transition.slideDownIn', {
                duration: 200,
                display: 'none'
            });
        else
            Velocity(form, 'reverse', {
                duration: 200,
                display: 'none'
            });
    });

    if (form.querySelector('.error-msg').textContent.replace(/\s/g, '').length > 0) {
        Velocity(form, 'transition.slideDownIn', {
            duration: 200,
            display: 'none'
        });
    }

};

/*------------------------------------------
  Doctor actions on edit profile
------------------------------------------*/
var doctorActions = {

    init: function() {
        // DOM Elements
        this.formLivechat = document.querySelector('#add_livechat');
        this.displayLivechatBtn = document.querySelector('a[href="#add_livechat"]');
        this.formArticle = document.querySelector('#add_article');
        this.displayArticleBtn = document.querySelector('a[href="#add_article"]');
        this.addContentFieldBtn = document.querySelector('#add_content_field > a');
        this.removeContentFieldBtn = document.querySelectorAll('.delete-content-field');
        this.addFocusPointBtn = document.querySelector('#add_focus_point > a');
        this.removeFocusPointBtn = document.querySelectorAll('.delete-focus-point');

        // Create base button for removing flexible contents
        this.rmContentFieldBtn = this.createLinkNode('#', 'X', 'delete-content-field');
        this.rmFocusPointBtn = this.createLinkNode('#', 'X', 'delete-focus-point');

        // DOM Events Handlers
        this.displayLivechatBtn.addEventListener('click', this.displayForm());
        this.displayArticleBtn.addEventListener('click', this.displayForm());
        this.addContentFieldBtn.addEventListener('click', this.addContent('.article-content-field', this.rmContentFieldBtn));
        this.addFocusPointBtn.addEventListener('click', this.addContent('.article-focus-point', this.rmFocusPointBtn));

        for (var i = 0; i < this.removeContentFieldBtn.length; i++) {
            this.removeContentFieldBtn[i].addEventListener('click', this.removeContent());
        }
    },

    // Create a <a> element from params
    createLinkNode: function(href, text, className) {
        var node = document.createElement('a');
        node.setAttribute('href', href);
        node.textContent = text;
        addClass(node, className);
        return node;
    },

    // Slide in/out forms when needed
    displayForm: function() {
        var _this = this;

        return function(e) {
            e.preventDefault();

            var targetForm = document.querySelector(this.getAttribute('href'));
            var activeForm = document.querySelector('form.active');

            var currentBtn = this;
            var otherBtn = this.parentNode.querySelector('a:not([href="' + this.getAttribute('href') + '"])');

            if (activeForm) {
                if (activeForm.id !== this.id)
                    removeClass(otherBtn, 'btn-grey');

                Velocity(activeForm, 'reverse', {
                    duration: 200,
                    display: 'none',
                    complete: function() {
                        removeClass(activeForm, 'active');
                    }
                });
            }

            if (targetForm !== activeForm) {
                removeClass(currentBtn, 'btn-grey');
                addClass(otherBtn, 'btn-grey');

                Velocity(targetForm, 'transition.slideDownIn', {
                    duration: 200,
                    display: 'none',
                    complete: function() {
                        addClass(targetForm, 'active');
                    }
                });
            }
        };
    },

    // Replace form elements values from params
    replaceFormElValues: function(label, el, index, resetValue) {
        var value = label.getAttribute('for').replace(/\d+/, index);
        label.textContent = label.textContent.replace(/\d+/, index + 1);
        label.setAttribute('for', value);
        el.setAttribute('name', value);
        el.setAttribute('id', value);
        if (resetValue)
            el.value = '';
    },

    // Insert new content by duplicating an existing one
    // and updating its values
    addContent: function(baseEltClass, rmContentBtn) {
        var _this = this;

        return function(e) {
            e.preventDefault();

            // Duplicate existing node and prepare new values
            var existingContent = document.querySelectorAll(baseEltClass);
            var newNode = existingContent[0].cloneNode(true);
            var newNodeIndex = existingContent.length;
            var nodeFields = newNode.querySelectorAll('.stacked-field');

            for (var i = 0; i < nodeFields.length; i++) {
                _this.replaceFormElValues(
                    nodeFields[i].querySelector('label'),
                    nodeFields[i].querySelector('input') || nodeFields[i].querySelector('textarea'),
                    newNodeIndex,
                    true
                );
            }

            // Insert a link to remove the fields to be added
            var link = rmContentBtn.cloneNode(true);
            newNode.insertBefore(link, newNode.firstChild);

            // Append the new fields in the form
            insertAfter(newNode, existingContent[newNodeIndex - 1]);

            // Register click handler for the remove button
            link.addEventListener('click', _this.removeContent());
        };
    },

    // Remove a previously added content and update values
    // of remaining ones
    removeContent: function() {
        var _this = this;

        return function(e) {
            e.preventDefault();

            var contentToRm = this.parentNode;
            var contentParent = contentToRm.parentNode;

            // Remove the content
            contentToRm.parentNode.removeChild(contentToRm);

            // Get the remaining content
            var remainingContent = contentParent.querySelectorAll('.' + contentToRm.className);

            // Updates values of remaining contents to reflect deletion
            for (var i = 0; i < remainingContent.length; i++) {
                var nodeFields = remainingContent[i].querySelectorAll('.stacked-field');

                for (var j = 0; j < nodeFields.length; j++) {
                    _this.replaceFormElValues(
                        nodeFields[j].querySelector('label'),
                        nodeFields[j].querySelector('input') || nodeFields[j].querySelector('textarea'),
                        i,
                        false
                    );
                }
            }
        };
    }

};

/*------------------------------------------
  User edit profile
------------------------------------------*/
var userEdit = {

    init: function() {
        this.editBtn = document.querySelector('a[href="#user_edit"]');
        this.profileView = document.querySelector('.profile:not(.profile-edit)');
        this.editView = document.querySelector('.profile-edit');
        this.avatarLabel = document.querySelector('label[for="user_avatar"]');
        this.avatarInput = document.querySelector('#user_avatar');
        this.avatarValue = document.querySelector('#user_avatar_value');
        this.cancelBtn = document.querySelector('button[type="reset"]');
        this.editMode = false;

        var _this = this;

        this.editBtn.addEventListener('click', function(e) {
            e.preventDefault();
            _this.switchEdit();
        });

        this.cancelBtn.addEventListener('click', this.switchEdit.bind(this));

        // As IE isn't able to link a <label for="id"> to a 'display: none' <input>
        // we disable the default behavior of the <label for="id"> and then
        // trigger the click on the input manually
        this.avatarLabel.addEventListener('click', function(e) {
            e.preventDefault();
            _this.avatarInput.click();
        });
        this.avatarInput.addEventListener('change', this.updateAvatarValue.bind(this, this.avatarBtn));
    },

    switchEdit: function() {
        // Animate in/out the edit form for user profile
        var seq;

        if (!this.editMode) {
            seq = [{
                e: this.profileView,
                p: 'transition.slideRightOut',
                o: {
                    duration: 300
                }
            }, {
                e: this.editView,
                p: 'transition.slideLeftIn',
                o: {
                    duration: 300
                }
            }];
            this.editMode = true;
        } else {
            seq = [{
                e: this.editView,
                p: 'transition.slideLeftOut',
                o: {
                    duration: 300
                }
            }, {
                e: this.profileView,
                p: 'transition.slideRightIn',
                o: {
                    duration: 300
                }
            }];
            this.editMode = false;
        }

        Velocity.RunSequence(seq);
    },

    updateAvatarValue: function(el) {
        // Update the text value of the avatarValue element
        // Removes the fakepath and use only the filename
        var val = el.value.replace(/^.*\\/, '');
        this.avatarValue.innerHTML = val;
    }

};

/*------------------------------------------
  Search Bar
------------------------------------------*/
var searchBar = {

    init: function(params) {
        this.form = document.querySelector(params.form) || undefined;
        this.searchInput = document.querySelector(params.searchInput) || undefined;
        this.slugButtons = document.querySelectorAll(params.slugButtons) || undefined;
        this.resultsClassSuffix = params.resultsClassSuffix || '-results';
        this.currentSearch = document.querySelector(params.currentSearch) || undefined;
        this.resultsContainer = document.querySelector(params.resultsContainer);
        this.searchTypeInput = document.querySelector(params.searchTypeInput) || undefined;
        this.searchCount = document.querySelector(params.searchCount) || undefined;
        this.noResult = document.querySelector(params.noResult) || undefined;
        this.slugsMode = params.slugsMode || false;
        this.beforeRender = params.beforeRender || undefined;
        this.beforeSearch = params.beforeSearch || undefined;
        this.emptySearch = params.emptySearch || undefined;
        this.requestAction = params.requestAction || 'get_operations_and_articles_by_body_part';
        this.views = {};

        if (this.form)
            this.setup();
        else
            this.search();
    },

    setup: function() {
        var _this = this;
        var debounce;

        // Disable form submission
        this.form.addEventListener('submit', function(e) {
            e.preventDefault();
            return false;
        });

        // Update query slug when clicking button
        if (this.slugsMode) {
            for (var i = 0; i < this.slugButtons.length; i++) {
                this.slugButtons[i].addEventListener('click', this.updateSlug);
            }
        }

        // Search for contents matching criterias on keyup
        this.searchInput.addEventListener('keyup', function(e) {

            if (e.keyCode === 13) {
                // Enter key = start search immediately
                clearTimeout(debounce);
                _this.search();
            } else {
                if (_this.currentSearch)
                    _this.currentSearch.innerHTML = this.value;
                clearTimeout(debounce);
                debounce = setTimeout(function() {
                    _this.search();
                }, 500);
            }

        });

        // Init search on page load if search params exist
        var searchValue = this.searchInput.value;
        var slugValue = this.slugsMode ? this.searchInput.getAttribute('data-slug') : '';

        if (searchValue.length > 0 || slugValue.length > 0) {
            if (this.currentSearch)
                this.currentSearch.innerHTML = searchValue;

            if (slugValue.length > 0) {
                var slugButton = document.querySelector('.btn-picto[data-slug=' + slugValue + ']');
                this.updateSlug.call(slugButton);
            } else {
                this.search();
            }
        }
    },

    updateSlug: function() {
        var _this = searchBar;
        var newSlug = this.getAttribute('data-slug');
        var slugs = _this.searchInput.getAttribute('data-slug');
        slugs = slugs.length > 0 ? slugs.split(',') : [];

        toggleClass(this, 'active-slug');

        if (slugs.length > 0) {

            var i = slugs.indexOf(newSlug);

            if (i < 0)
                slugs.push(newSlug);
            else
                slugs.splice(i, 1);

            _this.searchInput.setAttribute('data-slug', slugs.join());

        } else {
            _this.searchInput.setAttribute('data-slug', newSlug);
        }

        _this.search();
    },

    search: function() {
        if (this.beforeSearch)
            this.beforeSearch.call(this);

        var slug = this.slugsMode ? this.searchInput.getAttribute('data-slug') : '';

        if (this.searchInput === undefined || this.searchInput.value.length > 0 || slug.length > 0) {
            removeClass(this.resultsContainer, 'hidden');
            addClass(this.resultsContainer, 'searching');

            var params = {
                action: this.requestAction,
                query: this.searchInput ? this.searchInput.value : '',
                type: this.searchInput ? this.searchTypeInput.value : ''
            };

            if (this.slugsMode)
                params.slug = slug;

            ajax.newRequest(
                'POST',
                '/wp-admin/admin-ajax.php',
                params,
                this.render, // -> Callback
                this // -> Context for callback
            );

        } else {
            if (this.emptySearch)
                this.emptySearch.call(this);
            addClass(this.resultsContainer, 'hidden');
        }
    },

    render: function(data) {
        var _this = this;

        if (this.beforeRender)
            this.beforeRender.call(this);

        this.clearViews();

        // Display count results
        var countResults = 0;

        function buildBlock(resultType) {
            return function(content) {
                var output = document.createElement('div');
                var published = (content.post_status === 'publish') ? true : false;
                var html = '';

                if (!published)
                    addClass(output, 'pending');

                html += published ? '<a class="result-cover" href="' + content.permalink + '">' : '<span class="result-cover">';
                if (content.cover)
                    html += '<img src="' + content.cover.sizes.thumbnail + '" alt="' + content.cover.title + '">';
                html += published ? '</a>' : '</span>';
                html += content.tags;
                html += '<h3>';
                html += published ? '<a href="' + content.permalink + '">' + content.post_title + '</a>' : content.post_title;
                html += '</h3>';
                html += '<p>' + content.description.trunc(185) + '</p>';
                output.innerHTML = html;

                _this.views[resultType].appendChild(output);
            };
        }

        for (var resultType in data) {
            this.views[resultType] = document.querySelector('.' + resultType + this.resultsClassSuffix);
            countResults = countResults + data[resultType].length;
        }

        if (this.searchCount)
            this.searchCount.innerHTML = countResults;

        if (countResults < 1) {
            if (this.noResult)
                removeClass(this.noResult, 'hidden');
        } else {
            if (this.noResult)
                addClass(this.noResult, 'hidden');

            for (resultType in data) {
                if (data[resultType].length > 0) {
                    removeClass(this.views[resultType], 'hidden');
                    data[resultType].forEach(buildBlock(resultType));
                } else {
                    addClass(this.views[resultType], 'hidden');
                }
            }

        }

        removeClass(_this.resultsContainer, 'searching');
    },

    clearViews: function() {
        //Clear operations results
        for (var view in this.views) {
            while (this.views[view].children.length > 1) {
                this.views[view].removeChild(this.views[view].lastChild);
            }
            addClass(this.views[view], 'hidden');
        }
    }

};

/*------------------------------------------
 Esthetic-taxo-view wrapper
 ------------------------------------------*/
function init_esthetic_home() {
    addClass(document.querySelector('.human.men'), 'hidden');

    var bodypartWomen = new BodyPart(true);
    var handleWomen = document.querySelectorAll('.human.women a');

    function womenClickHandler() {
        return function(e) {
            e.preventDefault();
            bodypartWomen.menuClicked(this);
        };
    }

    for (var i = 0; i < handleWomen.length; i++) {
        handleWomen[i].addEventListener('click', womenClickHandler());
    }

    var bodypartMen = new BodyPart(false);
    var handleMen = document.querySelectorAll('.human.men a');

    function menClickHandler() {
        return function(e) {
            e.preventDefault();
            bodypartMen.menuClicked(this);
        };
    }

    for (var j = 0; j < handleMen.length; j++) {
        handleMen[j].addEventListener('click', menClickHandler());
    }

    var mobile = false;
    if (window.outerWidth < 850) {
        mobile = true;
        setmobile(true);
        mobiletoggleBodypartSubmenu();
    }

    function closeParts() {
        bodypartMen.middleScroll = false;
        bodypartMen.closePart(); // fermeture de tous les bodyparts pour ne pas générer d'erreur
        bodypartWomen.middleScroll = false;
        bodypartWomen.closePart();
    }

    function toggleMobileActive() {
        closeParts();
        var sameBackground = hasClass(this.parentNode, 'mobileActive');
        var backgrounds = document.getElementsByClassName('mobileActive');
        for (var i = 0; i < backgrounds.length; i++) {
            removeClass(backgrounds[i], 'mobileActive');
        }

        if (!sameBackground) {
            addClass(this.parentNode, 'mobileActive');
        }

        setTimeout(function() {
            new Velocity(document.getElementsByClassName('mobileActive'), "scroll", {
                duration: 500,
                offset: -100,
                easing: "ease-in-out"
            });
        }, 100);
    }

    function mobiletoggleBodypartSubmenu() {
        var backgrounds = document.getElementsByClassName('background');
        for (var k = 0; k < backgrounds.length; k++) {
            backgrounds[k].addEventListener('click', toggleMobileActive);
        }
    }

    window.addEventListener("resize", function() {
        if (window.outerWidth < 850) {
            if (!mobile) {
                setmobile(true);
                mobiletoggleBodypartSubmenu();
                mobile = true;
                closeParts();
            }
        } else {
            if (mobile) {
                setmobile(false);
                mobile = false;
                closeParts();
            }
        }
    }, true);

    function setmobile(mobile) {
        bodypartMen.setMobile(mobile, ".human.men");
        bodypartWomen.setMobile(mobile, ".human.women");
    }

    function annimateBodyMenuSex(visibleElement, invisibleElement, self, duration) {
        new Velocity(visibleElement, "transition.slideLeftOut", duration);

        var timeoutSmoothScroll2 = setTimeout(function() {
            new Velocity(invisibleElement, "transition.slideRightIn", duration);
            addClass(visibleElement, 'hidden');
            removeClass(invisibleElement, 'hidden');
            clearTimeout(timeoutSmoothScroll2);
        }, (duration - 1));

        var timeoutSmoothScroll3 = setTimeout(function() {
            self.disabled = false;
            clearTimeout(timeoutSmoothScroll3);
        }, (2 * duration));
    }

    document.getElementById('switchButton').addEventListener('change', function() {
        this.disabled = true; // désactivation de la check box le temps de l'annimation
        annimateBodyMenuSex(document.querySelectorAll('.human:not(.hidden)')[0], document.querySelectorAll('.human.hidden')[0], this, 500);
        bodypartMen.middleScroll = false;
        bodypartMen.closePart(); // fermeture de tous les bodyparts pour ne pas générer d'erreur
        bodypartWomen.middleScroll = false;
        bodypartWomen.closePart();
    });

}

stickyElement = {

    init: function(params) {
        // Element to set sticky
        this.el = params.el;
        // Element next to el (on its right)
        this.nextEl = params.nextEl;
        // Margin between sticky element and top browser border
        this.extraOffsetTop = params.extraOffsetTop || 50;

        var _this = this;

        window.addEventListener('load', function(e) {
            // We need to wait for load event to be sure that the sizes and positions are correct
            _this.nextElBox = _this.getNextElBox();
            _this.nextElOriginMarginLeft = parseInt(window.getComputedStyle(_this.nextEl).getPropertyValue('margin-left').match(/\d+/)[0]);
            _this.elBox = _this.getElBox();

            window.addEventListener('scroll', _this.updateSticky());

            var resizeDebounce;
            window.addEventListener('resize', function(e) {
                // Reset element position to static while resizing to get its natural size
                // and then set back to sticky with new size
                if (hasClass(_this.el, 'active')) {
                    removeClass(_this.el, 'active');
                    _this.nextEl.style.removeProperty('margin-left');
                    _this.el.style.removeProperty('top');
                    _this.el.style.removeProperty('width');
                }
                clearTimeout(resizeDebounce);
                resizeDebounce = setTimeout(function() {
                    _this.nextElBox = _this.getNextElBox();
                    _this.elBox = _this.getElBox();
                    _this.updateSticky().call(this, e);
                }, 250);
            });

            // Call a first update to set the position if the page is loaded in the sticky area
            _this.updateSticky().call(this, e);
        });
    },

    getElBox: function() {
        return {
            top: this.el.offsetTop,
            left: this.el.offsetLeft,
            height: this.el.offsetHeight,
            width: this.el.offsetWidth
        };
    },

    getNextElBox: function() {
        // Conditional for top is a trick to avoid a bug that set the value to 0 when
        // resizing in sticky past mode causes the page to scroll and to set the element
        // to sticky active
        return {
            top: this.nextEl.offsetTop > 0 ? this.nextEl.offsetTop : this.nextElBox.top,
            bottom: this.nextEl.offsetTop + this.nextEl.offsetHeight
        };
    },

    updateSticky: function() {
        var _this = this;

        return function(e) {

            if (window.pageYOffset + _this.extraOffsetTop <= _this.nextElBox.top && hasClass(_this.el, 'active')) {
                // Scroll level is before sticky start point
                // Unset sticky active mode if active: turn back to static positionning
                removeClass(_this.el, 'active');
                _this.nextEl.style.removeProperty('margin-left');
                _this.el.style.removeProperty('top');
                _this.el.style.removeProperty('width');
            }

            if (window.pageYOffset + _this.extraOffsetTop >= _this.nextElBox.top && window.pageYOffset + _this.extraOffsetTop <= _this.nextElBox.bottom && !hasClass(_this.el, 'active')) {
                // Scroll level is beyond sticky start point and before sticky end point (i.e. we want sticky here)
                // Set sticky active mode: turn sticky element position to fixed
                removeClass(_this.el, 'past');
                _this.el.parentNode.style.removeProperty('position', 'relative');
                addClass(_this.el, 'active');
                _this.nextEl.style.setProperty('margin-left', _this.nextElOriginMarginLeft + _this.elBox.width + 'px');
                _this.el.style.setProperty('top', _this.extraOffsetTop + 'px');
                _this.el.style.setProperty('width', _this.elBox.width + 'px');
            }

            if (window.pageYOffset + _this.extraOffsetTop >= _this.nextElBox.bottom - _this.elBox.height) {
                // Scroll level is beyond sticky end point
                // Set sticky past mode: lock position and scroll naturally
                removeClass(_this.el, 'active');
                _this.nextEl.style.setProperty('margin-left', _this.nextElOriginMarginLeft + _this.elBox.width + 'px');
                _this.el.style.removeProperty('top');
                _this.el.style.removeProperty('width');
                _this.el.parentNode.style.setProperty('position', 'relative');
                addClass(_this.el, 'past');
            }

        };
    }

};

/*------------------------------------------
 Menu Responsive
 ------------------------------------------*/

function createResponsiveMenu() {
    addClass(document.querySelector('body > header nav'), 'mobile');

    var responsiveButton = document.createElement('a');
    responsiveButton.href = '#';

    var responsiveButonDiv = document.createElement('div');
    responsiveButonDiv.id = 'responsiveMenuButton';
    responsiveButonDiv.appendChild(responsiveButton);
    responsiveButonDiv.addEventListener('click', function() {
        toggleClass(document.querySelector('body > header'), 'active');
    }, false);

    document.querySelector('body > header').appendChild(responsiveButonDiv);

    var element = document.querySelector('.connection');
    var elementParent = document.querySelector('.connection').parentNode;
    elementParent.removeChild(element);
    elementParent.insertBefore(element, document.querySelector('.mobile >ul > li:first-child'));
}

function resetMenuToDesktop() {
    removeClass(document.querySelector('body > header nav'), 'mobile');
    if (document.getElementById('responsiveMenuButton')) {
        document.getElementById('responsiveMenuButton').parentNode.removeChild(document.getElementById('responsiveMenuButton'));
    }
    var element2 = document.querySelector('.connection');
    var elementParent2 = document.querySelector('.connection').parentNode;
    elementParent2.removeChild(element2);
    elementParent2.appendChild(element2);
}

/*------------------------------------------
 Responsive Sticky
 ------------------------------------------*/

function removeStickyForResponsive(stickyHtmlString) {
    var stickyTemp = document.querySelector('.sticky');
    stickyTemp.parentNode.removeChild(stickyTemp);

    stickyTemp.className = 'mobileAuthor card';

    document.querySelector('.single-article > .row').appendChild(stickyTemp);

}

function ResetStickyForDeskyop(stickyHtmlString) {
    document.querySelector('.mobileAuthor').parentNode.removeChild(document.querySelector('.mobileAuthor'));
    var parser = new DOMParser();
    var newSticky = parser.parseFromString(stickyHtmlString, "text/html").querySelector('.sticky');
    document.querySelector('.single-article > .row').insertBefore(newSticky, document.querySelector('.single-article > .row > .col.span_9'));

    stickyElement.init({
        el: document.querySelector('.sticky'),
        nextEl: document.querySelector('.sticky + .col')
    });
}


/*------------------------------------------
  Responsive js
 ------------------------------------------*/

function initResponsive() {
    var sticky = false;
    if (document.querySelector('.sticky')) {
        sticky = document.querySelector('.sticky').outerHTML;
    }

    var setResponsive = function(initSticky) {

        if (window.innerWidth < 927) {
            if (!document.querySelector('.mobile')) {
                createResponsiveMenu();


                if (initSticky) {
                    removeStickyForResponsive(initSticky);
                }
            }
        } else {
            if (document.querySelector('.mobile')) {
                resetMenuToDesktop();

                if (initSticky) {
                    ResetStickyForDeskyop(initSticky);
                }
            }
        }
    };



    setResponsive(sticky);


    window.addEventListener("resize", function() {
        setResponsive(sticky);
    }, true);


}


/*------------------------------------------
  DOM ready wrapper
------------------------------------------*/
document.addEventListener('DOMContentLoaded', function() {

    'use strict';

    // Init menu responsive checker
    if (document.querySelector('header')) initResponsive();

    // Init login-top form
    if (document.querySelector('#login_top')) logIn();

    // Init timer
    if (document.querySelector('.timer')) {
        if (document.querySelector('.timer .time.animate')) timer();

        // Hide timer when user clicks the cross
        document.querySelector('.close-icon').addEventListener('click', function(e) {
            e.preventDefault();

            new Velocity(document.querySelector('.timer'), {
                opacity: 0,
                height: 0,
                padding: 0
            }, {
                display: 'none'
            }, 500);
        });
    }

    // Init signup form
    if (document.querySelector('.sign-up')) {
        signUp();
        var signUpForm = new FormValidator('#signup');
    }

    // Init actions on doctor profile
    if (document.querySelector('#add_livechat')) {
        doctorActions.init();
        var addArticle = new FormValidator('#add_article');
        var addLivechat = new FormValidator('#add_livechat');
    }

    // Init contact form
    if (document.querySelector('#contact')) var contact = new FormValidator('#contact');

    // Init user edit profilefile
    if (document.querySelector('a[href="#user_edit"]')) userEdit.init();

    // Init search bar reconstructive
    if (document.querySelector('#rec_search')) searchBar.init({
        form: '#rec_search',
        searchInput: '#search_req',
        slugButtons: '.slug-buttons button',
        currentSearch: '#current-search',
        resultsContainer: '.results',
        searchTypeInput: '#search-type',
        searchCount: '#search-count',
        noResult: '.no-result',
        slugsMode: true
    });

    // Init search bar plastic
    if (document.querySelector('#plastic_search')) searchBar.init({
        form: '#plastic_search',
        searchInput: '#search_req',
        currentSearch: '#current-search',
        resultsContainer: '.results',
        searchTypeInput: '#search-type',
        searchCount: '#search-count',
        noResult: '.no-result',
        beforeSearch: function() {
            var checkbox = document.querySelector('.checkbox-genre');
            var classGender = '.women';

            if (checkbox.querySelector('#switchButton').checked)
                classGender = '.men';

            var human = document.querySelector('.human' + classGender);

            addClass(human, 'searching');
        },
        beforeRender: function() {
            var checkbox = document.querySelector('.checkbox-genre');
            var classGender = '.women';

            if (checkbox.querySelector('#switchButton').checked)
                classGender = '.men';

            var human = document.querySelector('.human' + classGender);

            addClass(human, 'hidden');
            addClass(checkbox, 'hidden');
        },
        emptySearch: function() {
            var checkbox = document.querySelector('.checkbox-genre');
            var classGender = '.women';

            if (checkbox.querySelector('#switchButton').checked)
                classGender = '.men';

            var human = document.querySelector('.human' + classGender);

            removeClass(human, 'searching');
            removeClass(human, 'hidden');
            removeClass(checkbox, 'hidden');
        }
    });

    // Init search bar articles
    if (document.querySelector('#articles_search')) searchBar.init({
        form: '#articles_search',
        searchInput: '#search_req',
        currentSearch: '#current-search',
        resultsContainer: '.results',
        searchTypeInput: '#search-type',
        searchCount: '#search-count',
        noResult: '.no-result',
        requestAction: 'get_articles_by_query',
        beforeRender: function() {
            var existing = document.querySelector('.list-articles');
            addClass(existing, 'hidden');
        },
        emptySearch: function() {
            var existing = document.querySelector('.list-articles');
            removeClass(existing, 'hidden');
        }
    });

    // Init contributions
    if (document.querySelector('#contributions')) searchBar.init({
        resultsContainer: '.results',
        requestAction: 'get_contributions',
        beforeSearch: function() {
            var container = document.querySelector('.category');
            var el = document.createElement('span');
            addClass(el, 'loader');
            el.textContent = 'Chargement...';
            container.appendChild(el);
        },
        beforeRender: function() {
            var el = document.querySelector('.loader');
            el.parentNode.removeChild(el);
        }
    });

    // Init esthetic-home
    if (document.querySelector('.esthetic-home')) init_esthetic_home();

    // Operation Page: section background color manager
    if (document.querySelector('article.single-operation')) {
        var container = document.querySelector('article.single-operation .alt-sections');
        var count = container.querySelectorAll('section').length;

        // If the number of sections is odd, we add a reverse class
        // to invert the sections background color, ensuring that the last
        // one will not be of the same background color thant the next contextual
        // section (e.g. related articles)
        if (count % 2 !== 0) {
            addClass(container, 'reverse');
        }
    }
    if (!document.querySelector('.mobile')) {
        if (document.querySelector('.sticky')) {
            stickyElement.init({
                el: document.querySelector('.sticky'),
                nextEl: document.querySelector('.sticky + .col')
            });
        }
    }

    if (document.querySelector('.download-chat')) {
        var btn = document.querySelector('.download-chat');
        var select = document.querySelector('#article_chat_download');

        select.addEventListener('change', function(e) {
            var href = btn.getAttribute('href');
            href = href.replace(/\=(.*)/, '=' + this.value);
            btn.setAttribute('href', href);
        });
    }

    if (document.querySelector('#livechat_date')) {
        moment.locale('fr');
        var field = document.querySelector('#livechat_date');
        var datepicker = new Pikaday({
            field: field,
            format: 'YYYY-MM-DD',
            minDate: moment().toDate(Date.now()),
            i18n: {
                previousMonth: 'Mois précédent',
                nextMonth: 'Mois suivant',
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                weekdaysShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S']
            },
        });
        datepicker.adjustPosition();
    }

    var popups = {};
    if (document.querySelector('.livechat')) {
        var popupsEl = document.querySelectorAll('.chat-popup');
        for (var i = 0; i < popupsEl.length; i++) {
            popups[popupsEl[i].id] = new Popup(popupsEl[i]);
        }
    }

    if (document.querySelector('.chat-unconnect')) {
        popups.connectPopup.triggerPopup();
    }
    window.addEventListener("message", displayPopup, false);

    function displayPopup(evt) {
        if (evt.data == "ban") {
            popups.banPopup.triggerPopup();
        } else if (evt.data == "end") {
            popups.endPopup.triggerPopup();
        }
    }
});

// Source: http://codepen.io/clearwavedesigns/details/fFuKH
// Modified by Clément Paris

/*
 Copyright 2014 by CLEARWAVE DESIGNS, LLC.

 Licensed under the Apache License, Version 2.0
 http://www.apache.org/licenses/LICENSE-2.0

 Author: Adam Carson
 Website: http://clearwavedesigns.com
 Dependencies:  N/A

 Name(s): VIOIncJS (Vanilla JS)
 Description: Animated number incrementor plugin with customizable controls.
 */

var VIO_INC = function(obj) {
  var that = this;
  this.elem = obj.elem;
  this.input = (this.elem.nodeName.toLowerCase() === 'input') ? true: false;
  this.value = parseFloat(that.elem.getAttribute('data-inc-value')) || 0;
  this.duration = parseInt(this.elem.getAttribute('data-inc-duration')) || 1000;
  this.delay = parseInt(this.elem.getAttribute('data-inc-delay')) || 0;
  this.decimal = ((obj.decimal > 2) ? 2 : obj.decimal) || 0;
  this.currency = obj.currency || '';
  this.speed = ((obj.speed < 30) ? 30 : obj.speed) || 30;
  this.count = 0;
  this.increment = this.value / (this.duration / this.speed);
  this.interval = null;
  this.regex = /\B(?=(\d{3})+(?!\d))/g;
  this.run = function() {
    that.count += that.increment;
    that.prep = '';
    if(parseInt(that.count).toString().length < 2) {
      that.prep = '0';
    }
    if (that.count < that.value) {
      (that.input) ? that.elem.value = that.prep + that.currency + (that.count).toFixed(that.decimal).toString().replace(that.regex, ',') : that.elem.innerHTML = that.prep + that.currency + (that.count).toFixed(that.decimal).toString().replace(that.regex, ',');
    } else {
      clearInterval(that.interval);
      (that.input) ? that.elem.value = that.prep + that.currency + (that.value).toFixed(that.decimal).toString().replace(that.regex, ',') : that.elem.innerHTML = that.prep + that.currency + (that.value).toFixed(that.decimal).toString().replace(that.regex, ',');
    }
  };
  setTimeout(function() {
    that.interval = setInterval(that.run, that.speed);
  }, that.delay);
  this.reset = function() {
    clearInterval(that.interval);
    that.value = parseFloat(that.elem.getAttribute('data-inc-value')) || 0;
    that.duration = parseInt(that.elem.getAttribute('data-inc-duration')) || 0;
    that.increment = that.value / (that.duration / that.speed);
    that.delay = parseInt(that.elem.getAttribute('data-inc-delay')) || 0;
    that.count = 0;
    that.interval = setInterval(this.run, this.speed);
  };
};
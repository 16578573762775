/*------------------------------------------
  FormValidator
  Uses data-* attributes to validate fields
  Doesn't support select elements
------------------------------------------*/
var FormValidator = function(form) {

  this.form = document.querySelector(form);

  this.config = {
    submit: this.form.querySelector('button[type=submit]'),
    inputs: this.form.querySelectorAll('.floating-validations input:not([type="hidden"]), .floating-validations textarea'),
    errorClass: '.error-msg',
    mailReg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneReg: /^([0-9]){10}$/,
    dateReg: /^([0-9]){4}\-([0-9]){2}\-([0-9]){2}/,
    whiteSpaceReg: /\s/,
    numberReg: /^\d+$/
  };

  // Define errors messages
  this.errors = {
    error_required: 'Ce champ est obligatoire et ne peut-être vide',
    error_nospace: 'Ce champ ne peut pas contenir d\'espace',
    error_mail: 'Cette adresse e-mail est invalide',
    error_phone: 'Ce numéro de téléphone est invalide',
    error_minlength: 'Le mot de passe doit faire au moins {x} caractères de long', // Use {x} to be replaced by the value
    error_match: 'Les mots de passes ne correspondent pas',
    error_date: 'Cette date est invalide',
    error_number: 'Ce champ doit contenir un nombre',
    error_rpps: 'Ce numéro RPPS est invalide'
  };

  this.setup();

};

FormValidator.prototype.setup = function() {

  var _this = this;

  function inputHandler() {
    var check;

    return function(e) {
      check = _this.checkInput(this, false);
      if(!check.valid && check.error) {
        _this.config.submit.setAttribute('disabled', true);
        _this.displayError(this, check.error);
      } else if(check.valid) {
        _this.clearError(this);

        if(_this.form.querySelectorAll('.v-error').length < 1)
          _this.config.submit.removeAttribute('disabled');
      }
    };
  }

  function submitHandler() {
    return function(e) {
      e.preventDefault();
      _this.checkInputs(false);

      if(_this.form.querySelectorAll('.v-error').length > 0) {
        _this.config.submit.setAttribute('disabled', true);
        return false;
      } else {
        _this.config.submit.removeAttribute('disabled');
        this.submit();
        return true;
      }
    };
  }

  if(hasClass(this.form, 'invalid')) {
    // If errors were returned (i.e. the form has an invalid class)
    this.config.submit.setAttribute('disabled', true);
    this.checkInputs(true);
  }

  // Attach listeners on inputs
  for(var i = 0; i < this.config.inputs.length ; i++) {
    this.config.inputs[i].addEventListener('blur', inputHandler());
    this.config.inputs[i].addEventListener('keyup', inputHandler());

    if(this.config.inputs[i].getAttribute('type') === 'checkbox')
      this.config.inputs[i].addEventListener('change', inputHandler());
  }

  // Listen for form submission
  this.form.addEventListener('submit', submitHandler());

};

FormValidator.prototype.checkInputs = function(backErrors) {

  for(var i = 0; i < this.config.inputs.length; i++) {
    var check = this.checkInput(this.config.inputs[i], backErrors);

    if(!check.valid && check.error) {
      this.displayError(this.config.inputs[i], check.error);
    } else if(check.valid) {
      this.clearError(this.config.inputs[i]);
    }
  }

};

FormValidator.prototype.checkInput = function(el, backErrors) {

  var inputStatus = {
    valid: true,
    error: false
  };

  if(backErrors) {

    // Back-end returned errors
    // Use returned errors as errors messages

    var errorEl = document.getElementById(el.id).parentNode.querySelector(this.config.errorClass);

    if(errorEl && errorEl.textContent.replace(/\s/g, '').length > 0) {
      inputStatus.valid = false;
      inputStatus.error = errorEl.textContent;
    } else {
      inputStatus.valid = true;
      inputStatus.error = false;
    }

  } else {

    // Check conditional input on checkbox checked
    if(el.getAttribute('data-if')) {
      if(!document.querySelector(el.getAttribute('data-if')).checked) {
        inputStatus.valid = false;
        inputStatus.error = false;
        return inputStatus;
      }
    }
    // Check if is required
    if(el.getAttribute('data-required')) {
      if(el.getAttribute('type') === 'checkbox') {
        if(!el.checked) {
          inputStatus.valid = false;
          inputStatus.error = this.errors.error_required;
          return inputStatus;
        }else {
          this.clearError(el);
        }
      }else {
        if(el.value === '') {
          inputStatus.valid = false;
          inputStatus.error = this.errors.error_required;
          return inputStatus;
        }else {
          this.clearError(el);
        }
      }
    }
    // Check if is nospace
    if(el.getAttribute('data-nospace')) {
      if(this.config.whiteSpaceReg.test(el.value)) {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_nospace;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if is valid mail
    if(el.getAttribute('data-type') === 'mail') {
      if(!this.config.mailReg.test(el.value)) {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_mail;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if is valid phone number
    if(el.getAttribute('data-type') === 'phone') {
      if(!this.config.phoneReg.test(el.value.replace(/\s+|\-|\./g, '')) && el.value !== '') {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_phone;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if is valid RPPS number
    if(el.getAttribute('data-type') === 'rpps') {
      if(!this.config.numberReg.test(el.value.replace(/\s+/g, '')) && el.value !== '') {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_rpps;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if is valid date
    if(el.getAttribute('data-type') === 'date') {
      if(!this.config.dateReg.test(el.value)) {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_date;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if is a number
    if(el.getAttribute('data-type') === 'number') {
      if(!this.config.numberReg.test(el.value)) {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_number;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }
    // Check if fields values match
    if(el.getAttribute('data-match')) {
      if(document.querySelector(el.getAttribute('data-match')).value !== el.value) {
        inputStatus.valid = false;
        inputStatus.error = this.errors.error_match;
        return inputStatus;
      } else {
        this.clearError(el);
      }
    }
    // Check if min length is respected
    if(el.getAttribute('data-minlength')) {
      var minLength = parseInt(el.getAttribute('data-minlength'));
      if(el.value.length < minLength) {
        var errorMsg = this.errors.error_minlength.replace('{x}', minLength);
        inputStatus.valid = false;
        inputStatus.error = errorMsg;
        return inputStatus;
      }else {
        this.clearError(el);
      }
    }

  }

  return inputStatus;

};

FormValidator.prototype.displayError = function(el, errorMsg) {
  removeClass(el, 'v-ok');
  addClass(el, 'v-error');
  // Get the element for the error and insert the message
  var errorEl = document.getElementById(el.id).parentNode.querySelector(this.config.errorClass);
  if(errorEl) errorEl.innerHTML = errorMsg;
};

FormValidator.prototype.clearError = function(el) {
  removeClass(el, 'v-error');
  addClass(el, 'v-ok');
  // Get the element for the error and remove the message
  var errorEl = document.getElementById(el.id).parentNode.querySelector(this.config.errorClass);
  if(errorEl) errorEl.innerHTML = '';
};
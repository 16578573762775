function BodyPart(launch) {
    this.queryUrl = "/wp-admin/admin-ajax.php";
    this.loaderUrl = "/wp-content/themes/ips/images/puff.svg"; // url du loader
    this.baseChild = this.createMenuHtml(); // création de la base HTML qui servira au menu
    this.open = false; // booleen qui définit l'état actuel du menu
    this.animation = 0; // type d'animation à lancer ( à voir sans setAnimation() )
    this.mobile = false;

    // ces variables de stockage permettent de gagner en lisibilité et définir la prochaine animation à lancer
    this.oldElement = false; // variable de strockage de l'ancien lien cliqué
    this.oldParent = false; // variable de strockage de l'ancienne catégorie du menu
    this.mobile = false;
    this.middleScroll = false;

    if (launch) {
        this.init();
    }
}
BodyPart.prototype.init = function() {
    setTimeout(function() {
        if (document.getElementById('search-element')) {
            document.getElementById('search-element').click();
        }
    }, 50);

};
BodyPart.prototype.menuClicked = function(el) {
    var self = this;
    var currentParent = el.parentNode.parentNode.parentNode.parentNode;
    var listparent = el.parentNode.parentNode.parentNode;
    var scrollDestination = currentParent;
    var scrollOffset = -40;

    if (!self.open) { // le volet menu est férmé
        this.animation = 0;
        //self.resetHtml() // on s'assure qu'aucune data ne rique de perturber le chargement
        self.openPart(el, listparent, currentParent);
        self.loadData(el);
        self.oldElement = el;
        self.oldParent = currentParent;
        self.middleScroll = true;
    } else { // le volet menu est déjà ouvert
        self.setAnimation(self.oldParent, currentParent);
        if (self.oldParent == currentParent) { // l'élément cliqué est de la même catégorie
            if (self.oldElement == el) { // l'élément cliqué est le même
                self.middleScroll = true;
                self.animate(2, document.getElementById('dynamic-data'), 1250);

                var sameElementTiemeout = setTimeout(function() { // un timeout pour que l'annim ait le temps de se jouer avant le chargement de la data
                    self.closePart(); // on ferme le volet

                    clearTimeout(sameElementTiemeout);
                }, 1250);
                self.oldElement = false;
                self.oldParent = currentParent;

            } else { // l'élément cliqué est un autre élément
                // on met en srurbrillance le nouvel élément
                self.middleScroll = false;
                removeClass(self.oldElement, 'active');
                addClass(el, 'active');

                self.animate(2 + this.animation, document.getElementById('dynamic-data'), 1250);

                var overlayTimeOut = setTimeout(function() { // un timeout pour que l'annim ait le temps de se jouer avant le chargement de la data
                    document.getElementById('overlay').removeAttribute('style'); // on affiche l'overlay
                    document.getElementById('bodyPart').removeAttribute("style"); // on enlève la hauteur fixée en fonction de l'élément qui était chargé dynamiquement
                    self.loadData(el);

                    clearTimeout(overlayTimeOut);
                }, 1249);
                self.oldElement = el;
                self.oldParent = currentParent;

            }
        } else { // l'élément cliqué est d'une autre catégorie
            self.middleScroll = false;
            self.animate(self.animation + 2, document.getElementById('dynamic-data'), 1250);

            var overlayTimeOut2 = setTimeout(function() { // un timeout pour que l'annim ait le temps de se jouer avant le chargement de la data
                document.getElementById('overlay').removeAttribute('style'); // on affiche l'overlay
                self.closePart();

                clearTimeout(overlayTimeOut2);
            }, 500);


            var otherCategoryTimeout = setTimeout(function() { // on ouvre le menu apreès l'animation de fermeture de l'ancien
                self.openPart(el, listparent, currentParent);
                self.loadData(el);
                clearTimeout(otherCategoryTimeout);
            }, 850);


            self.oldElement = el;
            self.oldParent = currentParent;
        }


    }
};

BodyPart.prototype.setAnimation = function(oldParent, curentParent) {

    var self = this;
    if (oldParent == curentParent) {
        self.animation = 1;

    } else {
        self.animation = 0;
    }


};

BodyPart.prototype.animate = function(Animation, object, duration) {

    var animationArray = ['slideDownIn', 'slideRightIn', 'slideUpOut', 'slideRightOut'];
    new Velocity(object, "transition." + animationArray[Animation], duration);

    return true;
};

BodyPart.prototype.resetHtml = function() {
    if (document.getElementById('dynamic-data')) {
        document.getElementById('dynamic-data').parentNode.removeChild(document.getElementById('dynamic-data'));
    }
};

BodyPart.prototype.loadData = function(el) {
    var self = this;

    ajax.newRequest(
        'POST',
        self.queryUrl, {
            action: 'get_operations_and_articles_by_body_part',
            slug: el.getAttribute("data-slug"), // ou "nez", etc ...
            query: "",
            type: "chirurgie-esthetique"
        },
        this.render, // -> Callback
        this // -> Context for callback
    );
};

BodyPart.prototype.openPart = function(el, listparent, currentParent) {
    var self = this;
    addClass(listparent, 'active');
    addClass(el, 'active');

    var frag = document.createDocumentFragment();
    frag.appendChild(this.baseChild);
    currentParent.appendChild(frag);

    document.getElementById('overlay').removeAttribute('style'); // on s'assure que l'overlay apparait

    if (document.getElementById('bodyPart')) {

        var timeout = setTimeout(function() { // un léger timeout pour que l'annim css fonctionne
            addClass(document.getElementById('bodyPart'), 'open');
            clearTimeout(timeout);
        }, 50);

        // on confirme l'ouverure du menu
        this.open = true;

    }

    self.oldElement = el; // on set l'oldElement

};

BodyPart.prototype.closePart = function() {
    var self = this;

    if (document.getElementById('bodyPart')) {
        document.getElementById('bodyPart').style.height = document.getElementById('bodyPart').offsetHeight;
        var timeout = setTimeout(function() { // un léger timeout pour que l'annim css fonctionne
            document.getElementById('bodyPart').removeAttribute("style"); // on enlève la hauteur fixée en fonction de l'élément qui était chargé dynamiquement
            clearTimeout(timeout);
        }, 50);
    }
    removeClasses(document.getElementsByClassName('mobileActive'), 'mobileActive');
    removeClasses(document.getElementsByClassName('active'), 'active'); // en enlève la classe active sur le menu
    if (document.getElementById('bodyPart')) { //en enlève l classe open pour lancer l'annim css
        removeClass(document.getElementById('bodyPart'), 'open');
    }

    var closeTimout = setTimeout(function() { // un timeout qui enlève le node #bodypart du dome après que l'anim css se soit terminée
        if (document.getElementById('bodyPart')) {
            document.getElementById('bodyPart').parentNode.removeChild(document.getElementById('bodyPart'));
        }
        clearTimeout(closeTimout);
    }, 300);

    if (self.middleScroll) {
        var scrollDestination = document.getElementById('bodyPart').parentNode;
        var scrollOffset = -40;
        if (self.mobile) {
            scrollOffset = -130;
        }
        new Velocity(scrollDestination, "scroll", {
            duration: 500,
            offset: scrollOffset,
            easing: "ease-in-out"
        });
    }


    // comme le menu vient de se fermer on set this.open sur false
    this.open = false;
};


BodyPart.prototype.createMenuHtml = function() {

    var myDiv = document.createElement("div");
    myDiv.id = "bodyPart";
    var overlay = document.createElement("div");
    overlay.id = "overlay";
    var im = document.createElement("img");
    im.src = this.loaderUrl;
    overlay.appendChild(im);
    myDiv.appendChild(overlay);
    return myDiv;

};


BodyPart.prototype.render = function(data) {
    var self = this;

    self.resetHtml();
    var wrapper = document.createElement('div');
    wrapper.id = 'dynamic-data';
    var closeLink = '<a id="closeBodypart" href="javascript:void(0);">Fermer</a>';
    wrapper.innerHTML = closeLink;
    document.getElementById('bodyPart').appendChild(wrapper);

    if (data.operations.length > 0) {

        var operationElement = document.createElement('div');
        operationElement.className = 'operations-esthetic';
        operationElement.innerHTML = '<h2>Opérations</h2>';

        var operationWrapper = document.createElement('div');
        operationWrapper.className = 'list-articles';
        var operationRow = document.createElement('div');
        operationRow.className = 'row';
        // Render operations results in view
        data.operations.forEach(function(operation, index) {

            var output = document.createElement('div');
            output.classList.add("col");
            output.classList.add("span_4");
            output.classList.add("article-block");
            var html;
            html = '<a href="' + operation.permalink + '"><img src="' + operation.cover.sizes.large + '" alt"' + operation.cover.title + '"></a>';
            html += operation.tags;
            html += '<h3><a href="' + operation.permalink + '">' + operation.post_title + '</a></h3>';
            html += '<p>' + operation.description.trunc(185) + '</p>';
            html += '<a href="' + operation.permalink + '">Lire</a>';
            output.innerHTML = html;
            operationRow.appendChild(output);
        });
        operationWrapper.appendChild(operationRow);
        operationElement.appendChild(operationWrapper);

        document.getElementById('dynamic-data').appendChild(operationElement);
    }

    if (data.articles.length > 0) {
        var articleElement = document.createElement('div');
        articleElement.className = 'articles-esthetic';
        articleElement.innerHTML = '<h2>Articles</h2>';

        var articlesWrapper = document.createElement('div');
        articlesWrapper.className = 'list-articles';
        var articlesRow = document.createElement('div');
        articlesRow.className = 'row';

        // Render articles results in view
        data.articles.forEach(function(article, index) {
            var output = document.createElement('div');
            output.classList.add("col");
            output.classList.add("span_4");
            output.classList.add("article-block");
            var html;
            html = '<a class="result-cover" href="' + article.permalink + '"><img src="' + article.cover.sizes.large + '" alt"' + article.cover.title + '"></a>';
            html += article.tags;
            html += '<h3><a href="' + article.permalink + '">' + article.post_title + '</a></h3>';
            html += '<p>' + article.description.trunc(185) + '</p>';
            html += '<a href="' + article.permalink + '">Lire</a>';
            output.innerHTML = html;
            articlesRow.appendChild(output);
        });
        articlesWrapper.appendChild(articlesRow);
        articleElement.appendChild(articlesWrapper);

        document.getElementById('dynamic-data').appendChild(articleElement);
    }

    if (data.operations.length < 1 && data.articles.length < 1) {
        var noResultEl = document.createElement('h2');
        noResultEl.classList.add('no-result');
        noResultEl.textContent = "Aucun résultat disponible dans cette catégorie.";
        document.getElementById('dynamic-data').appendChild(noResultEl);
    }

    var scrollDestination = document.getElementById('bodyPart').parentNode;
    var scrollOffset = -40;
    if (self.mobile) {
        scrollDestination = document.getElementById('bodyPart');
        scrollOffset = -175;
    }
    new Velocity(scrollDestination, "scroll", {
        duration: 500,
        offset: scrollOffset,
        easing: "ease-in-out"
    });

    document.getElementById('bodyPart').style.height = 'auto';
    self.animate(self.animation, document.getElementById('dynamic-data'), 1250);

    document.getElementById('closeBodypart').addEventListener('click', function(e) {
        e.preventDefault();
        self.animate(self.animation + 2, document.getElementById('dynamic-data'), 1250);

        var overlayTimeOut2 = setTimeout(function() { // un timeout pour que l'annim ait le temps de se jouer avant le chargement de la data
            document.getElementById('overlay').removeAttribute('style'); // on affiche l'overlay
            self.closePart();

            clearTimeout(overlayTimeOut2);
        }, 1250);
    });
    document.getElementById('overlay').style.opacity = 0;


    var removeOverlayTimeout = setTimeout(function() {
        document.getElementById('overlay').style.display = 'none';
        clearTimeout(removeOverlayTimeout);

    }, 300);

};
BodyPart.prototype.setMobile = function(isMobile, scope) {
    if (isMobile != this.mobile) {
        this.mobile = isMobile;
        if (this.mobile) {
            this.prepareMobile(scope);
        } else {
            this.restoreDesktop(scope);
        }
    }
};

BodyPart.prototype.prepareMobile = function(scope) {
    this.restoreDesktop(scope);
    var parts = document.querySelectorAll(scope + ' .parts');
    for (var i = 0; i < parts.length; i++) {
        prepareMobileHtml(parts[i]);
    }


    function prepareMobileHtml(element) {

        var parent = element.parentNode;
        var div = document.createElement("div");
        div.className = 'background';
        var span = document.createElement("span");
        div.appendChild(span);

        parent.insertBefore(div, element);
    }
};

BodyPart.prototype.restoreDesktop = function(scope) {
    var element = document.querySelectorAll(scope + ' .background');
    for (var i = 0; i < element.length; i++) {
        element[i].parentNode.removeChild(element[i]);
    }
};

/**
 * Created by Chatnoir on 29/05/15.
 */
function Popup(content) {
    this.popupContent = content;
    this.init();
}
Popup.prototype.init = function(){
    var self = this;
    if(document.getElementById(self.popupContent.id)) {
        document.getElementById(self.popupContent.id).parentNode.removeChild(document.getElementById(self.popupContent.id));
    }
};

Popup.prototype.triggerPopup = function(){
    this.clearPopup();
    this.createPopupContent();
    this.openPopup();
    this.startTriggeringClosingPopup();
};


Popup.prototype.createPopupContent = function(){
    var self = this;
    var myDiv = document.createElement("div");
    myDiv.id = "popupOverlay";

    var closingBtn = document.createElement("a");
    closingBtn.id = 'popupCloseButton';
    closingBtn.href = '#';

    var thePopup = document.createElement("aside");
    thePopup.id = 'popup';

    thePopup.appendChild( self.popupContent);
    thePopup.appendChild(closingBtn);

    myDiv.appendChild(thePopup);

    document.body.appendChild(myDiv);
};

Popup.prototype.clearPopup = function(){
    if(document.getElementById('popupOverlay')){
        document.getElementById('popupOverlay').parentNode.removeChild(document.getElementById('popupOverlay'));
    }
};
Popup.prototype.startTriggeringClosingPopup = function(){
    var self = this;
    if(document.getElementById('popupCloseButton'))
        document.getElementById('popupCloseButton').addEventListener("click", self.closePopup, false);
    if(document.getElementById('popupOverlay'))
        document.getElementById('popupOverlay').addEventListener("click", self.closePopup, false);
};

Popup.prototype.openPopup = function(){
    var popup = document.getElementById('popupOverlay');

    var loading = [
        { elements: popup, properties: { width: '100%' } , options: {duration: 300}},
        { elements: popup, properties: { height: '100%' }, options: {
            duration: 500,
            complete: function () {
                new Velocity(document.querySelectorAll('#popup > *'), "transition.slideDownIn", 800);
            }
        }
        }
    ];

    Velocity.RunSequence(loading);
};

Popup.prototype.closePopup = function(e){
    e.preventDefault();

    var popup = document.getElementById('popupOverlay');
    var unloading = [
        { elements: popup, properties: { height: '.25rem' }, options: {duration: 500}},
        { elements: popup, properties: { width: '0%' } , options: {
            duration: 300,
            complete: function () {
                if(document.getElementById('popupOverlay')){
                    document.getElementById('popupOverlay').parentNode.removeChild(document.getElementById('popupOverlay'));
                }
            }
        }
        }
    ];

    new Velocity(document.querySelectorAll('#popup > *'), "transition.slideUpOut", 800);
    var closeTimeOut = setTimeout(function(){
        Velocity.RunSequence(unloading);
        clearTimeout(closeTimeOut);
    }, 800);

};